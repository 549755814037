import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import { Link } from "gatsby"

import Background from "../../../assets/images/servicesHeader/web_icon.svg"

import d365 from "../../../assets/images/d365.png"
import iMac from "../../../assets/images/iMac-psd-mockup-template-1.png"

class Drupal extends React.Component {

    render() {
      return (
    <div id="cms">
        <SEO title={'Microsoft Dynamics 365 Development | ICON Worldwide '}
        description="Ready to start your migration? See for yourself that migrating data can be quick and easy. Our team will support a safeguarded network, as well as the full security of your records!"
        canonical={'https://icon-worldwide.com/services/microsoft-dynamics-365-development'}/>
        <Header headerTitle="Microsoft Dynamics 365 Development" headerSubTitle="Expert data migration services"  headerBackground={Background}/>
        <div id="magnolia">
            <div className="cms-inner">
                <div className="left-part">
                    <h1>Ready to start your migration? See for yourself that migrating data can be quick and easy. Our team will support a safeguarded network, as well as the full security of your records!</h1>
                    <h2 className="bigText">Our developers implement the best data protection practices to move your records under a safe connection. As a team with extensive expertise in data import of different types of management software, we will take into consideration all of the little details, in order to carry out the transfer in the most precise way.</h2>
                </div>
                <div className="right-part">
                    <div className="right-inner">
                        <img src={d365} alt="ICON Worldwide is D365 expert" title="ICON Worldwide is D365 expert"/>
                        <h3>Are you considering a Migration from SAP to Dynamics 365?</h3>
                        <p>Talk to us, we’ll be happy to walk you through the pros and cons of a migration to Dynamics 365 for your project.</p>
                        <div className="bigger-space">
                            <h3>Our staffing models</h3>
                            <p>We offer three models of cooperation:</p>
                            <h4>Project based</h4>
                            <h4>Time and materials</h4>
                            <h4>Dedicated team</h4>
                            <Link to="/contact-us"><div className="contact-btn">Let's start your new project together</div></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="silver-bg">
                <div className="cms-inner">
                    <div className="part">
                        <h2>Why Dynamics 365?</h2>
                        <p>What makes Microsoft Dynamics so popular is its easy-to-use feature and it puts at the company’s disposal a wide array of Microsoft products. The tools it integrates are efficient and can meet the varying needs of the company. This product is cost-effective, and some organizations have welcomed Microsoft Dynamics with an open heart</p>
                    </div>
                    <div className="part" id="d365">
                        <p>Most of the companies are now adopting Microsoft Dynamics because of the flexibility in business operations. Seamless integration, reduction in the learning curve, real-time analysis of big data, smart decision-making, and enhanced ROI are some of the benefits which have made Microsoft Dynamics a boon for the companies</p>
                    </div>
                </div>
            </div>
            <div className="cms-inner last-inner">
                <div className="part">
                    <h2>Main Benefits of Using Dynamics 365</h2>
                    <p className="grey-bullet-element"><strong>1. Easy to Use and Deploy</strong><br/>You can use Microsoft Dynamics 365 in the cloud or in a hybrid cloud environment. It’s simple to manage and deploy in whichever way you need based on your budget and infrastructure.</p>
                    <p className="grey-bullet-element"><strong>2. Fully Integrates With Microsoft Products</strong><br/> Dynamics 365 easily integrates with other Microsoft products, including the full Office 365 suite. These integrations allow companies to move from Dynamics 365 to Outlook for communication, to Power BI for data analytics or to SharePoint for documentation.<br/><br/>The easier it is to work and move between apps, the less time employees have to spend transferring data between applications and learning new platforms. </p>
                    <p className="grey-bullet-element"><strong>3. Reduced Sales Cycle</strong><br/>Dynamics 365 gives sales teams access to a wealth of insightful customer data – whether they’re working in the field or taking a call in the office. Your team will be able to craft fast responses, and gain a complete picture of each prospect’s journey, allowing them to create more targeted selling experiences.</p>
                    <p className="grey-bullet-element"><strong>4. Customizable</strong><br/>Dynamics 365 features a variety of modules and built-in workflow tools you can use to automate tasks across all channels. If the pre-built tools don’t fit exactly what you need, Dynamics 365 also allows you to customize your flows by defining additional fields and new objects in the system. The system also has a flexible architecture, deployment options, and pricing tiers so you can tailor the platform to your budget, team size, and KPIs.</p>
                </div>
                <div className="part">
                    <p className="grey-bullet-element"><strong>5. Enhanced Customer Service Experience</strong><br/>Dynamics 365 collects massive amounts of valuable data on each customer. It analyzes the websites people visit, how they interact with brands, which communities they are a part of and more. Its BI tool can also identify customer sentiments, buying patterns and brand loyalty – data points that can’t be measured quantitatively but are highly relevant to the sales process.</p>
                    <p className="grey-bullet-element"><strong>6. Scalability</strong><br/>Dynamics 365 can easily scale up or down depending on your current team size, budget and workload. Dynamics 365, like most other Microsoft cloud products, is based on an easy-to-change monthly subscription.</p>
                    <p className="grey-bullet-element"><strong>7. Increased Productivity</strong><br/>Your employees can access just about any kind of data they need with Dynamics 365, so they can work more efficiently and cut down time searching for information. Employees can make better, more informed decisions, and they have all of the tools and insights they need at their fingertips.<br/><br/>Instead of having to access ERP, CRM, data and Office applications separately, users can find everything they need on one platform.</p>
                    <img className="full-img" src={iMac} alt="ICON Worldwide is D365 expert" title="ICON Worldwide is D365 expert"/>
                </div>
                <div className="yellowLine"></div>
                <ServicesBottomNav/>
            </div>
            </div>
        <Footer noScrollbar="true"/>
    </div>
    )
}
}

export default Drupal
